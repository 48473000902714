const form_data_manager = {
  setFormData: function (form_data_portal, form_data_db) {
    // STEP ONE - Supra Point of Contact
    form_data_portal.supra_point_of_contact =
      form_data_db?.supra_point_of_contact;
    form_data_portal.company_name = form_data_db?.company_name;
    form_data_portal.company_phone = form_data_db?.company_phone;
    form_data_portal.company_fax = form_data_db?.company_fax;
    form_data_portal.company_address = form_data_db?.company_address;
    form_data_portal.selected_state = form_data_db?.selected_state;
    form_data_portal.company_city = form_data_db?.company_city;
    form_data_portal.company_zip = form_data_db?.company_zip;
    form_data_portal.federal_id_number = form_data_db?.federal_id_number;
    form_data_portal.years_in_business = form_data_db?.years_in_business;
    form_data_portal.type_of_business = form_data_db?.type_of_business;
    form_data_portal.how_refered = form_data_db?.how_refered;
    form_data_portal.credit_requested = form_data_db?.credit_requested;
    form_data_portal.projected_loads = form_data_db?.projected_loads;
    form_data_portal.dyb = form_data_db?.dyb;
    // STEP TWO
    form_data_portal.company_name_invoice = form_data_db?.company_name_invoice;
    form_data_portal.contact_billing = form_data_db?.contact_billing;
    //
    form_data_portal.delay_event_contact_name =
      form_data_db?.delay_event_contact_name;
    form_data_portal.delay_event_contact_title =
      form_data_db?.delay_event_contact_title;
    form_data_portal.delay_event_contact_email =
      form_data_db?.delay_event_contact_email;
    form_data_portal.delay_event_contact_phone =
      form_data_db?.delay_event_contact_phone;
    form_data_portal.delay_event_contact_fax =
      form_data_db?.delay_event_contact_fax;
    form_data_portal.delay_event_contact_mailing_address =
      form_data_db?.delay_event_contact_mailing_address;
    //
    form_data_portal.delay_event_contact_supervisor_name =
      form_data_db?.delay_event_contact_supervisor_name;
    form_data_portal.delay_event_contact_supervisor_title =
      form_data_db?.delay_event_contact_supervisor_title;
    form_data_portal.delay_event_contact_supervisor_email =
      form_data_db?.delay_event_contact_supervisor_email;
    form_data_portal.delay_event_contact_supervisor_phone =
      form_data_db?.delay_event_contact_supervisor_phone;
    form_data_portal.delay_event_contact_supervisor_fax =
      form_data_db?.delay_event_contact_supervisor_fax;
    form_data_portal.delay_event_contact_supervisor_mailing_address =
      form_data_db?.delay_event_contact_supervisor_mailing_address;
    //
    form_data_portal.invoice_mail = form_data_db?.invoice_mail;
    form_data_portal.invoice_email = form_data_db?.invoice_email;
    form_data_portal.recipients = form_data_db?.recipients;
    form_data_portal.send_per_container = form_data_db?.send_per_container;
    form_data_portal.send_per_booking_or_masterbill =
      form_data_db?.send_per_booking_or_masterbill;
    form_data_portal.send_per_po = form_data_db?.send_per_po;
    //
    form_data_portal.bill_of_landing = form_data_db?.bill_of_landing;
    form_data_portal.load_rate_confirmation =
      form_data_db?.load_rate_confirmation;
    form_data_portal.delivery_order = form_data_db?.delivery_order;
    form_data_portal.exam_fee_receipt = form_data_db?.exam_fee_receipt;
    form_data_portal.bridge_toll_gate_fee_receipt =
      form_data_db?.bridge_toll_gate_fee_receipt;
    form_data_portal.interchange = form_data_db?.interchange;
    form_data_portal.proof_of_delivery = form_data_db?.proof_of_delivery;
    form_data_portal.dry_run_tickets = form_data_db?.dry_run_tickets;
    form_data_portal.per_diem_invoice = form_data_db?.per_diem_invoice;
    form_data_portal.chassis_pool_invoice = form_data_db?.chassis_pool_invoice;
    form_data_portal.pier_pass_receipt = form_data_db?.pier_pass_receipt;
    form_data_portal.booking_confirmation = form_data_db?.booking_confirmation;
    form_data_portal.scale_ticket = form_data_db?.scale_ticket;
    form_data_portal.delay_time_screen_shots =
      form_data_db?.delay_time_screen_shots;
    form_data_portal.demurrage_receipt = form_data_db?.demurrage_receipt;
    form_data_portal.flip_charge = form_data_db?.flip_charge;
    //
    form_data_portal.additional_procedure = form_data_db?.additional_procedure;
    form_data_portal.chassis_ssl_contracts =
      form_data_db?.chassis_ssl_contracts;
    // STEP THREE
    form_data_portal.name_of_bank = form_data_db?.name_of_bank;
    form_data_portal.bank_address = form_data_db?.bank_address;
    form_data_portal.account_number = form_data_db?.account_number;
    form_data_portal.bank_representative = form_data_db?.bank_representative;
    form_data_portal.bank_representative_phone =
      form_data_db?.bank_representative_phone;
    form_data_portal.bank_representative_fax =
      form_data_db?.bank_representative_fax;
    // STEP FOUR
    form_data_portal.name_of_lender = form_data_db?.name_of_lender;
    form_data_portal.lender_account_number =
      form_data_db?.lender_account_number;
    form_data_portal.lender_bank_representative =
      form_data_db?.lender_bank_representative;
    form_data_portal.lender_bank_representative_phone =
      form_data_db?.lender_bank_representative_phone;
    form_data_portal.lender_bank_representative_fax =
      form_data_db?.lender_bank_representative_fax;
    // STEP FIVE
    form_data_portal.creditors = form_data_db?.creditors;
    form_data_portal.creditors_uploaded_files =
      form_data_db?.creditors_uploaded_files;
    // STEP SIXTH
    form_data_portal.top_customers = form_data_db?.top_customers;
    // STEP SEVENTH
    form_data_portal.signature_name = form_data_db?.signature_name;
    form_data_portal.signature_last_name = form_data_db?.signature_last_name;
    form_data_portal.signature_title = form_data_db?.signature_title;
    form_data_portal.signature_email = form_data_db?.signature_email;
    form_data_portal.signature_object = form_data_db?.signature_object;
    form_data_portal.signature_date = form_data_db?.signature_date;

    return form_data_portal;
  },
  formDataHasChanged(form_data_portal_last, form_data_portal_new) {
    return !(
      JSON.stringify(form_data_portal_last) ==
      JSON.stringify(form_data_portal_new)
    );
  },
};

export default form_data_manager;
