<template>
  <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Let's get this started</md-dialog-title>

    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('customer_name')">
                <label for="first-name">First Name</label>
                <md-input
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  v-model="form.customer_name"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.customer_name.required"
                  >The first name is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.customer_name.minlength"
                  >Invalid first name</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('customer_lastname')">
                <label for="last-name">Last Name</label>
                <md-input
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  v-model="form.customer_lastname"
                  :disabled="sending"
                />
                <span
                  class="md-error"
                  v-if="!$v.form.customer_lastname.required"
                  >The last name is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form.customer_lastname.minlength"
                  >Invalid last name</span
                >
              </md-field>
            </div>
          </div>

          <md-field :class="getValidationClass('email')">
            <label for="email">Email</label>
            <md-input
              type="email"
              name="email"
              id="email"
              autocomplete="email"
              v-model="form.email"
              :disabled="sending"
            />
            <span class="md-error" v-if="!$v.form.email.required"
              >The email is required</span
            >
            <span class="md-error" v-else-if="!$v.form.email.email"
              >Invalid email</span
            >
          </md-field>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions>
          <md-button class="md-accent" @click="showDialog = false"
            >Close</md-button
          >
          <md-button type="submit" class="md-primary" :disabled="sending"
            >Register</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </md-dialog>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "StartDialog",
  mixins: [validationMixin],
  data: () => ({
    showDialog: false,
    entity: null,
    form: {
      customer_name: null,
      customer_lastname: null,
      email: null,
    },
    sending: false,
    lastUser: null,
  }),
  validations: {
    form: {
      customer_name: {
        required,
        minLength: minLength(3),
      },
      customer_lastname: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
    },
  },
  created() {
    eventBus.$on("showStartDialog", () => {
      this.showDialog = true;
    });
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.customer_name = null;
      this.form.customer_lastname = null;
      this.form.email = null;
      this.sending = false;
    },
    to_base64() {},
    generateEntity() {
      this.entity =
        this.form.customer_name +
        "," +
        this.form.customer_lastname +
        "," +
        this.form.email;
    },
    showExistingRecordDialog(data, entity) {
      eventBus.$emit("showExistingCustomerDialog", data, entity);
    },
    saveUser() {
      this.sending = true;
      this.generateEntity();
      axios
        .post(process.env.VUE_APP_GET_CUSTOMER_BY_EMAIL, {
          value: this.form.email,
        })
        .then((result) => {
          console.log(result);
          if (result.data) {
            if (result.data.customer) {
              this.showDialog = false;
              this.clearForm();
              this.showExistingRecordDialog(result.data.customer, this.entity);
            } else {
              axios
                .post(process.env.VUE_APP_SAVE_CUSTOMER_INFO, {
                  customer: this.entity,
                })
                .then((result) => {
                  this.showMessageService(result.data.message);
                  this.entity = null;
                  this.$router.push({
                    name: "CustomerForm",
                    params: { customer: result.data.partition_key },
                  });
                  this.clearForm();
                })
                .catch((error) => {
                  this.showMessageService(error);
                });
            }
          }
        });
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
    showMessageService: function (msg) {
      eventBus.$emit("showMessageService", msg);
    },
  },
};
</script>

<style scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 768px;
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
