<template>
  <div id="landing-page">
    <HeadToolBar />
    <CustomerFormContent />
    <AddEditCreditors />
    <AddEditRecipients />
    <CreditorsUploadFileDialog />
  </div>
</template>

<script>
// @ is an alias to /src
import HeadToolBar from "../components/Shared/HeadToolBar.vue";
import CustomerFormContent from "../components/CustomerForm/CustomerFormContent.vue";
import AddEditCreditors from "../components/CustomerForm/AddEditCreditors.vue";
import AddEditRecipients from "../components/CustomerForm/AddEditRecipients.vue";
import CreditorsUploadFileDialog from "../components/CustomerForm/CreditorsUploadFileDialog.vue";

export default {
  name: "LandingPage",
  components: {
    HeadToolBar,
    CustomerFormContent,
    AddEditCreditors,
    AddEditRecipients,
    CreditorsUploadFileDialog,
  },
};
</script>

<style scoped>
#landing-page {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
