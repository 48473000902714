<template>
  <div id="landing-page">
    <HeadToolBar />
    <LandingContent />
    <StartDialog />
    <ExistingCustomerDialog />
  </div>
</template>

<script>
// @ is an alias to /src
import HeadToolBar from "../components/Shared/HeadToolBar.vue";
import LandingContent from "../components/LandingPage/LandingContent.vue";
import StartDialog from "../components/LandingPage/StartDialog.vue";
import ExistingCustomerDialog from "../components/LandingPage/ExistingCustomerDialog.vue";

export default {
  name: "LandingPage",
  components: {
    HeadToolBar,
    LandingContent,
    StartDialog,
    ExistingCustomerDialog,
  },
};
</script>

<style scoped>
#landing-page {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
