<template>
  <div>
    <md-toolbar class="md-primary" md-theme="orange-toolbar" md-elevation="24">
      <md-avatar class="md-avatar-icon md-accent" md-theme="orange-toolbar">
        <md-icon>support_agent</md-icon>
      </md-avatar>
      <h3 class="md-title" style="flex: 1">Supra Credit Application</h3>
    </md-toolbar>
  </div>
</template>

<style scoped>
.md-toolbar + .md-toolbar {
  margin-top: 16px;
}
</style>

<script>
export default {
  name: "HeadToolBar",
};
</script>

<style lang="scss" scoped>
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "orange-toolbar",
  (
    primary: md-get-palette-color(black, 500),
    accent: md-get-palette-color(red, 500),
  )
);

//@import "~vue-material/dist/base/theme";
@import "vue-material/dist/components/MdToolbar/theme";
@import "vue-material/dist/components/MdAvatar/theme";

.md-card {
  width: 320px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}
</style>
