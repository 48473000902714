<template>
  <div id="landing-page">
    <HeadToolBar />
    <ThankyouContent />
  </div>
</template>

<script>
// @ is an alias to /src
import HeadToolBar from "../components/Shared/HeadToolBar.vue";
import ThankyouContent from "../components/LandingPage/ThankyouContent.vue";

export default {
  name: "ThankyouPage",
  components: {
    HeadToolBar,
    ThankyouContent,
  },
};
</script>

<style scoped>
#landing-page {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
