<template>
  <div id="app">
    <router-view />
    <MessagesService />
  </div>
</template>

<script>
import MessagesService from "./components/Shared/MessagesService.vue";

export default {
  name: "App",
  components: {
    MessagesService,
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
