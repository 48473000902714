<template>
  <div>
    <div>
      <img src="/supra_customer.png" />
    </div>
    <md-empty-state
      md-label="REGISTER AS SUPRA CUSTOMER"
      md-description="and let's work together!"
    >
      <md-button
        class="md-primary md-raised"
        md-theme="landing-theme"
        @click.native="showStartDialog()"
        >Start</md-button
      >
    </md-empty-state>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "LandingContent",
  methods: {
    showStartDialog: function () {
      eventBus.$emit("showStartDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "landing-theme",
  (
    primary: md-get-palette-color(blue, A200),
    accent: md-get-palette-color(red, A200),
  )
);

//@import "~vue-material/dist/base/theme";
@import "vue-material/dist/components/MdEmptyState/theme";
@import "vue-material/dist/components/MdButton/theme";

.md-empty-state-icon {
  color: purple;
}

img {
  display: block;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}
</style>
