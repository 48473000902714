<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title v-if="this.is_inserting"
        >Insert new creditor</md-dialog-title
      >
      <md-dialog-title v-else>Edit creditor</md-dialog-title>

      <md-content class="md-scrollbar">
        <div class="inner-dialog">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label>Company Name</label>
                <md-input
                  v-model="creditorsData.creditor_company_name"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Contact Name</label>
                <md-input
                  v-model="creditorsData.creditor_contact_name"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Address</label>
                <md-input
                  v-model="creditorsData.creditor_address"
                  type="text"
                ></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label>City</label>
                <md-input
                  v-model="creditorsData.creditor_city"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Phone</label>
                <md-input
                  v-model="creditorsData.creditor_phone"
                  type="tel"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field>
                <label>Email</label>
                <md-input
                  v-model="creditorsData.creditor_email"
                  type="email"
                ></md-input>
              </md-field>
            </div>
          </div>
        </div>
      </md-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false"
          >Close</md-button
        >
        <md-button class="md-primary" @click.native="saveCreditor()"
          >Save</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "AddEditCreditors",
  data: () => ({
    is_inserting: false,
    showDialog: false,
    indexOfCreditor: null,
    creditorsData: {
      creditor_company_name: null,
      creditor_contact_name: null,
      creditor_address: null,
      creditor_city: null,
      creditor_phone: null,
      creditor_email: null,
    },
  }),
  created() {
    eventBus.$on("showAddEditCreditors", (item, index) => {
      this.showDialog = true;
      if (item) {
        this.is_inserting = false;
        this.creditorsData = item;
        this.indexOfCreditor = index;
      } else {
        this.is_inserting = true;
        this.creditorsData.creditor_company_name = null;
        this.creditorsData.creditor_contact_name = null;
        this.creditorsData.creditor_address = null;
        this.creditorsData.creditor_city = null;
        this.creditorsData.creditor_phone = null;
        this.creditorsData.creditor_email = null;
        this.indexOfCreditor = null;
      }
    });
  },
  methods: {
    saveCreditor: function () {
      let indexToUpdate = this.indexOfCreditor;
      let newCreditor = {
        creditor_company_name: this.creditorsData.creditor_company_name,
        creditor_contact_name: this.creditorsData.creditor_contact_name,
        creditor_address: this.creditorsData.creditor_address,
        creditor_city: this.creditorsData.creditor_city,
        creditor_phone: this.creditorsData.creditor_phone,
        creditor_email: this.creditorsData.creditor_email,
      };

      if (this.is_inserting) {
        eventBus.$emit("refreshCreditorsTable", newCreditor, true, null);
      } else {
        eventBus.$emit(
          "refreshCreditorsTable",
          newCreditor,
          false,
          indexToUpdate
        );
      }
      this.showDialog = false;
    },
    showMessageService: function (msg) {
      eventBus.$emit("showMessageService", msg);
    },
  },
};
</script>

<style scoped>
.md-dialog /deep/ .md-dialog-container {
  width: 950px;
  max-height: 650px;
}

.md-content {
  overflow: auto;
  margin-left: 24px;
}

.inner-dialog {
  width: calc(100% - 20px);
  margin: 0px 0px 6px 0px;
}
</style>
