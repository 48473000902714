<template>
  <div>
    <md-snackbar
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span>{{ msg }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "MessagesService",
  data: () => ({
    msg: null,
    showSnackbar: false,
    position: "left",
    duration: 5000,
    isInfinity: false,
  }),
  created() {
    eventBus.$on("showMessageService", (msg) => {
      this.msg = msg;
      this.showSnackbar = true;
    });
  },
};
</script>
