<template>
  <div>
    <md-empty-state
      class="md-primary"
      md-icon="done"
      md-label="Thank you!"
      md-description="A Supra customer service representative will contact you soon"
      ><md-button class="md-primary md-raised" to="/">Start again</md-button>
    </md-empty-state>
  </div>
</template>

<script>
export default {
  name: "ThankyouContent",
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "landing-theme",
  (
    primary: md-get-palette-color(blue, A200),
    accent: md-get-palette-color(red, A200),
  )
);

//@import "~vue-material/dist/base/theme";
@import "vue-material/dist/components/MdEmptyState/theme";
@import "vue-material/dist/components/MdButton/theme";

.md-empty-state-icon {
  color: purple;
}
</style>
