<template>
  <div class="customer-form-content">
    <md-content>
      <div class="md-headline">Hi, {{ customer_data.customer_name }}</div>
      <div class="md-subheading">Please fill in the following information</div>
    </md-content>
    <md-steppers :md-active-step.sync="active" md-vertical md-linear>
      <!-- ################################# STEP ONE ################################# -->
      <md-step
        id="first"
        md-label="CUSTOMER APPLICATION"
        md-description="Required"
        :md-error="firstStepError"
        :md-done.sync="first"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('supra_point_of_contact')">
                <label for="supra-point-of-contact"
                  >Supra Sales Rep <span><strong>* </strong></span></label
                >
                <md-input
                  name="supra-point-of-contact"
                  id="supra-point-of-contact"
                  v-model="form_data.supra_point_of_contact"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.supra_point_of_contact.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.supra_point_of_contact.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.supra_point_of_contact.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('company_name')">
                <label for="company-name"
                  >Company Name <span><strong>* </strong></span></label
                >
                <md-input
                  name="company-name"
                  id="company-name"
                  v-model="form_data.company_name"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_name.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_name.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_name.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('company_phone')">
                <label for="company-phone"
                  >Phone <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="company-phone"
                  id="company-phone"
                  v-model="form_data.company_phone"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_phone.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_phone.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_phone.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_phone.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('company_fax')">
                <label for="company-fax"
                  >Fax <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="company-fax"
                  id="company-fax"
                  v-model="form_data.company_fax"
                  maxlength="10"
                />
                <span class="md-error" v-if="!$v.form_data.company_fax.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_fax.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_fax.maxLength"
                  >Too long</span
                >
                <span class="md-error" v-if="!$v.form_data.company_fax.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter disclaimer-content">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-content
                class="md-elevation-4 disclaimer md-accent"
                md-theme="disclaimertheme"
              >
                <div>
                  By providing your phone number, you agree to receive text
                  messages from Supra National Express Inc related to your
                  service requests and occasional marketing updates. Consent is
                  not required for services. Msg frequency varies. Msg & data
                  rates may apply. Text <strong>STOP</strong> to cancel,
                  <strong>HELP</strong> for help. For more information about our
                  privacy policy
                  <a
                    href="https://www.snecorp.com/privacy-policy/"
                    target="_blank"
                    >click here</a
                  >.
                </div>
              </md-content>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field :class="getValidationClass('company_address')">
                <label for="company-address"
                  >Company Address <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="company-address"
                  id="company-address"
                  v-model="form_data.company_address"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_address.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_address.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_address.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-autocomplete
                v-model="form_data.selected_state"
                :md-options="states"
                md-dense
              >
                <label>State</label>

                <template
                  slot="md-autocomplete-item"
                  slot-scope="{ item, term }"
                >
                  <md-highlight-text :md-term="term">{{
                    item
                  }}</md-highlight-text>
                </template>

                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                  No states matching "{{ term }}" were found.
                </template>
              </md-autocomplete>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field :class="getValidationClass('company_city')">
                <label for="company-city"
                  >City <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="company-city"
                  id="company-city"
                  v-model="form_data.company_city"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_city.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_city.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_city.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field :class="getValidationClass('company_zip')">
                <label for="company-zip"
                  >ZIP <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="company-zip"
                  id="company-zip"
                  v-model="form_data.company_zip"
                  maxlength="5"
                />
                <span class="md-error" v-if="!$v.form_data.company_zip.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_zip.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_zip.maxLength"
                  >Too long</span
                >
                <span class="md-error" v-if="!$v.form_data.company_zip.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('federal_id_number')">
                <label for="federal-id-number"
                  >Federal ID Number <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="federal-id-number"
                  id="federal-id-number"
                  v-model="form_data.federal_id_number"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.federal_id_number.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.federal_id_number.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.federal_id_number.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.federal_id_number.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('years_in_business')">
                <label for="years-in-business"
                  >Number of years in Business
                  <span><strong>* </strong></span></label
                >
                <md-input
                  type="number"
                  name="years-in-business"
                  id="years-in-business"
                  v-model="form_data.years_in_business"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.years_in_business.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.years_in_business.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('type_of_business')">
                <label for="type-of-business"
                  >Type of Business <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="type-of-business"
                  id="type-of-business"
                  v-model="form_data.type_of_business"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.type_of_business.required"
                  >The field is required</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('how_refered')">
                <label for="how-refered"
                  >How refered <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="how-refered"
                  id="how-refered"
                  v-model="form_data.how_refered"
                />
                <span class="md-error" v-if="!$v.form_data.how_refered.required"
                  >The field is required</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('credit_requested')">
                <label for="credit-requested"
                  >Credit Requested <span><strong>* </strong></span></label
                >
                <span class="md-prefix">$</span>
                <md-input
                  type="number"
                  name="credit-requested"
                  id="credit-requested"
                  v-model="form_data.credit_requested"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.credit_requested.required"
                  >The field is required</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('projected_loads')">
                <label for="projected-loads"
                  >Projected Loads (Monthly)
                  <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="projected-loads"
                  id="projected-loads"
                  v-model="form_data.projected_loads"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.projected_loads.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.projected_loads.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('dyb')">
                <label for="dyb"
                  >D&B# <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="dyb"
                  id="dyb"
                  v-model="form_data.dyb"
                />
                <span class="md-error" v-if="!$v.form_data.dyb.required"
                  >The field is required</span
                >
                <span class="md-error" v-if="!$v.form_data.dyb.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>
        </md-content>
        <md-button
          class="md-raised md-primary"
          @click="setDone('first', 'second')"
          >Save</md-button
        >
      </md-step>

      <!-- ################################# STEP TWO ################################# -->
      <md-step
        id="second"
        md-label="BANKING REFERENCE"
        md-description="Required"
        :md-error="secondStepError"
        :md-done.sync="second"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('name_of_bank')">
                <label for="name-of-bank"
                  >Name of Bank <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="name-of-bank"
                  id="name-of-bank"
                  v-model="form_data.name_of_bank"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.name_of_bank.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.name_of_bank.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.name_of_bank.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('account_number')">
                <label for="account-number"
                  >Account Number <span><strong>* </strong></span></label
                >
                <md-input
                  name="account-number"
                  id="account-number"
                  maxlength="12"
                  v-model="form_data.account_number"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.account_number.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.account_number.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-field :class="getValidationClass('bank_address')">
                <label for="bank-address"
                  >Address <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="bank-address"
                  id="bank-address"
                  v-model="form_data.bank_address"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_address.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_address.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_address.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('bank_representative')">
                <label for="bank-representative"
                  >Representative <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="bank-representative"
                  id="bank-representative"
                  v-model="form_data.bank_representative"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_representative.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="getValidationClass('bank_representative_phone')"
              >
                <label for="bank-representative-phone"
                  >Phone <span><strong>* </strong></span></label
                ><md-input
                  type="tel"
                  name="bank-representative-phone"
                  id="bank-representative-phone"
                  v-model="form_data.bank_representative_phone"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_representative_phone.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative_phone.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative_phone.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_representative_phone.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field :class="getValidationClass('bank_representative_fax')">
                <label for="bank-representative-fax"
                  >Fax <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="bank-representative-fax"
                  id="bank-representative-fax"
                  v-model="form_data.bank_representative_fax"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_representative_fax.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative_fax.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.bank_representative_fax.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.bank_representative_fax.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>
        </md-content>

        <md-button
          class="md-raised md-primary"
          @click="setDone('second', 'third')"
          >Save</md-button
        >
        <!-- <md-button class="md-raised md-primary" @click="setError()"
          >Set error!</md-button
        > -->
      </md-step>

      <!-- ################################# STEP THREE ################################# -->
      <md-step
        id="third"
        md-label="CURRENT LENDING INFORMATION"
        md-description="Required"
        :md-error="thirdStepError"
        :md-done.sync="third"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('name_of_lender')">
                <label for="name-of-lender">Name of Lender</label>
                <md-input
                  type="text"
                  name="name-of-lender"
                  id="name-of-lender"
                  v-model="form_data.name_of_lender"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.name_of_lender.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.name_of_lender.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('lender_account_number')">
                <label for="lender-account-number">Account Number</label>
                <md-input
                  type="tel"
                  name="lender-account-number"
                  id="lender-account-number"
                  maxlength="10"
                  v-model="form_data.lender_account_number"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_account_number.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.lender_account_number.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_account_number.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="getValidationClass('lender_bank_representative')"
              >
                <label for="lender-bank-representative">Representative</label>
                <md-input
                  type="text"
                  name="lender-bank-representative"
                  id="lender-bank-representative"
                  v-model="form_data.lender_bank_representative"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_bank_representative.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.lender_bank_representative.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="getValidationClass('lender_bank_representative_phone')"
              >
                <label for="lender-bank-representative-phone">Phone</label>
                <md-input
                  type="tel"
                  name="lender-bank-representative-phone"
                  id="lender-bank-representative-phone"
                  v-model="form_data.lender_bank_representative_phone"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.lender_bank_representative_phone.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.lender_bank_representative_phone.maxLength
                  "
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_bank_representative_phone.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="getValidationClass('lender_bank_representative_fax')"
              >
                <label for="lender-bank-representative-fax">Fax</label>
                <md-input
                  type="tel"
                  name="lender-bank-representative-fax"
                  id="lender-bank-representative-fax"
                  v-model="form_data.lender_bank_representative_fax"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_bank_representative_fax.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.lender_bank_representative_fax.maxLength
                  "
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.lender_bank_representative_fax.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>
        </md-content>

        <md-button
          class="md-raised md-primary"
          @click="setDone('third', 'fourth')"
          >Save</md-button
        >
      </md-step>

      <!-- ################################# STEP FOUR ################################# -->
      <md-step
        id="fourth"
        md-label="LIST AT LEAST (3) THREE CURRENT CREDITORS"
        md-description="Required"
        :md-error="fourthStepError"
        :md-done.sync="fourth"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2">Current Creditors</span>
                <md-table
                  v-if="form_data.creditors"
                  v-model="form_data.creditors"
                  md-card
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item, index }"
                  >
                    <md-table-cell
                      md-label="Company Name"
                      md-sort-by="creditor_company_name"
                      >{{ item.creditor_company_name }}</md-table-cell
                    >
                    <md-table-cell
                      md-label="Contact Name"
                      md-sort-by="creditor_contact_name"
                      >{{ item.creditor_contact_name }}</md-table-cell
                    >
                    <md-table-cell
                      md-label="Address"
                      md-sort-by="creditor_address"
                      >{{ item.creditor_address }}</md-table-cell
                    >
                    <md-table-cell md-label="City" md-sort-by="creditor_city">{{
                      item.creditor_city
                    }}</md-table-cell>
                    <md-table-cell md-label="Zip" md-sort-by="creditor_zip">{{
                      item.creditor_zip
                    }}</md-table-cell>
                    <md-table-cell
                      md-label="Phone"
                      md-sort-by="creditor_phone"
                      >{{ item.creditor_phone }}</md-table-cell
                    >
                    <md-table-cell
                      md-label="Email"
                      md-sort-by="creditor_email"
                      >{{ item.creditor_email }}</md-table-cell
                    >
                    <md-table-cell md-label="Options">
                      <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                          <md-button
                            class="md-icon-button md-raised md-primary"
                            @click.native="showAddEditCreditors(item, index)"
                            ><md-icon>edit</md-icon
                            ><md-tooltip>Edit</md-tooltip></md-button
                          >
                          <md-button
                            class="md-icon-button md-raised md-accent"
                            @click.native="deleteCreditor(index)"
                            ><md-icon>delete</md-icon
                            ><md-tooltip>Delete</md-tooltip></md-button
                          >
                        </div>
                      </div>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center-left">
            <div class="md-layout-item md-size-5">
              <br />
              <md-button
                class="md-icon-button md-raised md-primary"
                @click.native="showAddEditCreditors()"
              >
                <md-icon>add</md-icon>
              </md-button>
            </div>
          </div>
          <br />
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2">Files uploaded</span>
                <md-table
                  v-if="fieldExists(form_data.creditors_uploaded_files)"
                  v-model="form_data.creditors_uploaded_files"
                  md-card
                >
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="File name" md-sort-by="name">{{
                      item.name
                    }}</md-table-cell>
                    <md-table-cell md-label="Size" md-sort-by="size">{{
                      item.size
                    }}</md-table-cell>
                    <md-table-cell
                      md-label="Last updated"
                      md-sort-by="updated_at"
                      >{{ item.updated_at }}</md-table-cell
                    >
                    <md-table-cell md-label="Url" md-sort-by="url">
                      <a v-bind:href="item.url" target="_blank">{{
                        item.name
                      }}</a>
                    </md-table-cell>
                    <md-table-cell md-label="Options">
                      <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                          <md-button
                            class="md-icon-button md-raised md-primary"
                            @click.native="gotoCreditorFile(item.url)"
                            ><md-icon>download</md-icon
                            ><md-tooltip>Download</md-tooltip></md-button
                          >
                          <md-button
                            class="md-icon-button md-raised md-accent"
                            @click.native="deleteCreditorFile(item)"
                            ><md-icon>delete</md-icon
                            ><md-tooltip>Delete</md-tooltip></md-button
                          >
                        </div>
                      </div>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center-left">
            <div class="md-layout-item md-size-5">
              <br />
              <md-button
                class="md-icon-button md-raised md-primary"
                @click.native="showUploadDialog()"
              >
                <md-icon>upload</md-icon>
              </md-button>
            </div>
          </div>
        </md-content>

        <md-button
          class="md-raised md-primary"
          @click="setDone('fourth', 'fifth')"
          >Save</md-button
        >
      </md-step>

      <!-- ################################# STEP FIFTH ################################# -->
      <md-step
        id="fifth"
        md-label="TOP 5 CUSTOMERS"
        md-description="Required"
        :md-error="fifthStepError"
        :md-done.sync="fifth"
      >
        <md-content>
          <div class="md-layout md-gutter disclaimer-content">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-content
                class="md-elevation-0 disclaimer md-accent"
                md-theme="disclaimertheme"
              >
                <div>
                  This information is optional and is intended to supplement our
                  understanding of your business's credit viability. However,
                  please note that your D&B (Dun & Bradstreet) credit assessment
                  should meet our required standards, we may request this
                  information to further evaluate your creditworthiness.
                </div>
              </md-content>
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center-left">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <br />
              <md-field>
                <label>Type here!</label>
                <md-input v-model="tempTopCustomer"></md-input>
                <span class="md-helper-text">Helper text</span>
                <md-button
                  class="md-raised md-primary"
                  @click.native="addTopCustomer()"
                >
                  Add Customer
                </md-button>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <div>
                <span class="md-body-2"
                  >Top 5 Customers we will be serving with you</span
                >

                <md-list class="md-dense md-elevation-3">
                  <md-list-item
                    v-for="(item, index) in form_data.top_customers"
                    :key="index"
                  >
                    <span class="md-list-item-text">{{ item }}</span>

                    <md-button
                      class="md-icon-button md-raised md-accent"
                      @click.native="deleteTopcustomer(index)"
                      ><md-icon>delete</md-icon
                      ><md-tooltip>Delete</md-tooltip></md-button
                    >
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </div>
          <br />
        </md-content>

        <md-button
          class="md-raised md-primary"
          @click="setDone('fifth', 'sixth')"
          >Save</md-button
        >
      </md-step>

      <!-- ################################# STEP SIXTH ################################# -->
      <md-step
        id="sixth"
        md-label="BILLING INFORMATION"
        md-description="Required"
        :md-error="sixthStepError"
        :md-done.sync="sixth"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('company_name_invoice')">
                <label for="company-name-invoice"
                  >Company Name on invoice
                  <span><strong>* </strong></span></label
                >
                <md-input
                  name="company-name-invoice"
                  id="company-name-invoice"
                  v-model="form_data.company_name_invoice"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.company_name_invoice.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_name_invoice.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.company_name_invoice.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('contact_billing')">
                <label for="contact-billing"
                  >Contact Billing <span><strong>* </strong></span></label
                >
                <md-input
                  name="contact-billing"
                  id="contact-billing"
                  v-model="form_data.contact_billing"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.contact_billing.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.contact_billing.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.contact_billing.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <span class="md-body-2"
                >Name of person to contact in the event of delayed payment of
                your invoices</span
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field :class="getValidationClass('delay_event_contact_name')">
                <label for="delay-event-contact-name">Name</label>
                <md-input
                  type="text"
                  name="delay-event-contact-name"
                  id="delay-event-contact-name"
                  v-model="form_data.delay_event_contact_name"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_name.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_name.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_name.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="getValidationClass('delay_event_contact_title')"
              >
                <label for="delay-event-contact-title"
                  >Title <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="delay-event-contact-title"
                  id="delay-event-contact-title"
                  v-model="form_data.delay_event_contact_title"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_title.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_title.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_title.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="getValidationClass('delay_event_contact_email')"
              >
                <label for="delay-event-contact-email"
                  >Email <span><strong>* </strong></span></label
                >
                <md-input
                  type="email"
                  name="delay-event-contact-email"
                  id="delay-event-contact-email"
                  v-model="form_data.delay_event_contact_email"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_email.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_email.email"
                  >Enter a valid email</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="getValidationClass('delay_event_contact_phone')"
              >
                <label for="delay-event-contact-phone"
                  >Phone <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="delay-event-contact-phone"
                  id="delay-event-contact-phone"
                  v-model="form_data.delay_event_contact_phone"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_phone.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_phone.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_phone.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_phone.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field :class="getValidationClass('delay_event_contact_fax')">
                <label for="delay-event-contact-fax"
                  >Fax <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="delay-event-contact-fax"
                  id="delay-event-contact-fax"
                  v-model="form_data.delay_event_contact_fax"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.delay_event_contact_fax.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_fax.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_fax.maxLength"
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.delay_event_contact_fax.numeric"
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_mailing_address')
                "
              >
                <label for="delay_event_contact_mailing_address"
                  >Mailing Address <span><strong>* </strong></span></label
                >
                <md-input
                  name="delay_event_contact_mailing_address"
                  id="delay_event_contact_mailing_address"
                  v-model="form_data.delay_event_contact_mailing_address"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_mailing_address.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_mailing_address.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_mailing_address.maxLength
                  "
                  >Too long</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <span class="md-body-2"
                >Name of supervisor of above-named person</span
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_supervisor_name')
                "
              >
                <label for="delay-event-contact-supervisor-name"
                  >Name <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="delay-event-contact-supervisor-name"
                  id="delay-event-contact-supervisor-name"
                  v-model="form_data.delay_event_contact_supervisor_name"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_name.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_name.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_name.maxLength
                  "
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_supervisor_title')
                "
              >
                <label for="delay-event-contact-supervisor-title"
                  >Title <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="delay-event-contact-supervisor-title"
                  id="delay-event-contact-supervisor-title"
                  v-model="form_data.delay_event_contact_supervisor_title"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_title.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_title.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_title.maxLength
                  "
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_supervisor_email')
                "
              >
                <label for="delay-event-contact-supervisor-email"
                  >Email <span><strong>* </strong></span></label
                >
                <md-input
                  type="email"
                  name="delay-event-contact-supervisor-email"
                  id="delay-event-contact-supervisor-email"
                  v-model="form_data.delay_event_contact_supervisor_email"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_email.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_email.email
                  "
                  >Enter a valid email</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_supervisor_phone')
                "
              >
                <label for="delay-event-contact-supervisor-phone"
                  >Phone <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="delay-event-contact-supervisor-phone"
                  id="delay-event-contact-supervisor-phone"
                  v-model="form_data.delay_event_contact_supervisor_phone"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_phone.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_phone.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_phone.maxLength
                  "
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_phone.numeric
                  "
                  >Only numbers allowed</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-25 md-small-size-100">
              <md-field
                :class="
                  getValidationClass('delay_event_contact_supervisor_fax')
                "
              >
                <label for="delay-event-contact-supervisor-fax"
                  >Fax <span><strong>* </strong></span></label
                >
                <md-input
                  type="tel"
                  name="delay-event-contact-supervisor-fax"
                  id="delay-event-contact-supervisor-fax"
                  v-model="form_data.delay_event_contact_supervisor_fax"
                  maxlength="10"
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_fax.required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_fax.minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_fax.maxLength
                  "
                  >Too long</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_fax.numeric
                  "
                  >Only numbers allowed</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field
                :class="
                  getValidationClass(
                    'delay_event_contact_supervisor_mailing_address'
                  )
                "
              >
                <label for="delay_event_contact_supervisor_mailing_address"
                  >Mailing Address <span><strong>* </strong></span></label
                >
                <md-input
                  name="delay_event_contact_supervisor_mailing_address"
                  id="delay_event_contact_supervisor_mailing_address"
                  v-model="
                    form_data.delay_event_contact_supervisor_mailing_address
                  "
                />
                <span
                  class="md-error"
                  v-if="
                    !$v.form_data.delay_event_contact_supervisor_mailing_address
                      .required
                  "
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_mailing_address
                      .minLength
                  "
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="
                    !$v.form_data.delay_event_contact_supervisor_mailing_address
                      .maxLength
                  "
                  >Too long</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <span>Invoices sent to Customer by: </span>
              <md-checkbox v-model="form_data.invoice_mail">Mail</md-checkbox>
              <md-checkbox v-model="form_data.invoice_email">Email</md-checkbox>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2"
                  >List of recipients which will receive the customer invoice by
                  email</span
                >
                <md-table
                  v-if="form_data.recipients"
                  v-model="form_data.recipients"
                  md-card
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item, index }"
                  >
                    <md-table-cell
                      md-label="Name of recipients"
                      md-sort-by="recipient_name"
                      >{{ item.recipient_name }}</md-table-cell
                    >
                    <md-table-cell
                      md-label="Emails Address"
                      md-sort-by="recipient_email"
                      >{{ item.recipient_email }}</md-table-cell
                    >
                    <md-table-cell md-label="Options">
                      <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                          <md-button
                            class="md-icon-button md-raised md-primary"
                            @click.native="showAddEditRecipients(item, index)"
                            ><md-icon>edit</md-icon
                            ><md-tooltip>Edit</md-tooltip></md-button
                          >
                          <md-button class="md-icon-button md-raised md-accent"
                            ><md-icon @click.native="deleteRecipient(index)"
                              >delete</md-icon
                            ><md-tooltip>Delete</md-tooltip></md-button
                          >
                        </div>
                      </div>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter md-alignment-center-left">
            <div class="md-layout-item md-size-5">
              <br />
              <md-button
                class="md-icon-button md-raised md-primary"
                @click.native="showAddEditRecipients()"
              >
                <md-icon>add</md-icon>
              </md-button>
            </div>
          </div>
          <br />
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2">Invoice options list below:</span>
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-checkbox v-model="form_data.send_per_container"
                >Send customer 1 invoice per container</md-checkbox
              >

              <md-checkbox v-model="form_data.send_per_booking_or_masterbill"
                >Send customer 1 invoice per booking or masterbill</md-checkbox
              ><md-checkbox v-model="form_data.send_per_po"
                >Send customer 1 invoice per PO #</md-checkbox
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2"
                  >Please check off the documents you would like attached with
                  your invoce:</span
                >
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-checkbox v-model="form_data.bill_of_landing"
                >Bill of Lading</md-checkbox
              >

              <md-checkbox v-model="form_data.delivery_order"
                >Delivery Order</md-checkbox
              >

              <md-checkbox v-model="form_data.interchange"
                >Interchange</md-checkbox
              >

              <md-checkbox v-model="form_data.proof_of_delivery"
                >Proof of Delivery</md-checkbox
              >

              <md-checkbox v-model="form_data.per_diem_invoice"
                >Per Diem Invoice</md-checkbox
              >

              <md-checkbox v-model="form_data.pier_pass_receipt"
                >Pier Pass Receipt</md-checkbox
              >

              <md-checkbox v-model="form_data.scale_ticket"
                >Scale Ticket</md-checkbox
              >

              <md-checkbox v-model="form_data.demurrage_receipt"
                >Demurrage Receipt</md-checkbox
              >
            </div>
            <div class="md-layout-item md-medium-size-50 md-small-size-100">
              <md-checkbox v-model="form_data.load_rate_confirmation"
                >Load/Rate Confirmation</md-checkbox
              >

              <md-checkbox v-model="form_data.exam_fee_receipt"
                >Exam Fee Receipt</md-checkbox
              >

              <md-checkbox v-model="form_data.bridge_toll_gate_fee_receipt"
                >Bridge Toll/Gate Fee Receipt</md-checkbox
              >

              <md-checkbox v-model="form_data.dry_run_tickets"
                >Dry Run Tickets</md-checkbox
              >

              <md-checkbox v-model="form_data.chassis_pool_invoice"
                >Chassis Pool Invoice</md-checkbox
              >

              <md-checkbox v-model="form_data.booking_confirmation"
                >Booking Confirmation</md-checkbox
              >

              <md-checkbox v-model="form_data.delay_time_screen_shots"
                >Delay Time Screen Shots</md-checkbox
              >

              <md-checkbox v-model="form_data.flip_charge"
                >Flip Charge</md-checkbox
              >
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2"
                  >Insert any additional procedure or requirements you would
                  like when these invoices are sent:</span
                >
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-field>
                <label>Additional procedure or requirements</label>
                <md-textarea
                  v-model="form_data.additional_procedure"
                ></md-textarea>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <div>
                <span class="md-body-2"
                  >Chassis SSL contracts: (Please list below if any)</span
                >
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-field>
                <label>Chassis SSL contracts</label>
                <md-textarea
                  v-model="form_data.chassis_ssl_contracts"
                ></md-textarea>
              </md-field>
            </div>
          </div>
        </md-content>
        <md-button
          class="md-raised md-primary"
          @click="setDone('sixth', 'seventh')"
          >Save</md-button
        >
      </md-step>

      <!-- ################################# STEP SEVENTH ################################# -->
      <md-step
        id="seventh"
        md-label="SIGNATURE"
        md-description="Required"
        :md-error="seventhStepError"
        :md-done.sync="seventh"
      >
        <md-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('signature_name')">
                <label for="signature-name"
                  >Name <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="signature-name"
                  id="signature-name"
                  v-model="form_data.signature_name"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.signature_name.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_name.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_name.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('signature_last_name')">
                <label for="signature-last-name"
                  >Last Name <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="signature-last-name"
                  id="signature-last-name"
                  v-model="form_data.signature_last_name"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.signature_last_name.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_last_name.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_last_name.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-medium-size-33 md-small-size-100">
              <md-field :class="getValidationClass('signature_title')">
                <label for="signature-title"
                  >Title <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="signature-title"
                  id="signature-title"
                  v-model="form_data.signature_title"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.signature_title.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_title.minLength"
                  >Too short</span
                >
                <span
                  class="md-error"
                  v-else-if="!$v.form_data.signature_title.maxLength"
                  >Too long</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-medium-size-100 md-small-size-100">
              <md-field :class="getValidationClass('signature_email')">
                <label for="signature-email"
                  >Email <span><strong>* </strong></span></label
                >
                <md-input
                  type="text"
                  name="signature-email"
                  id="signature-email"
                  v-model="form_data.signature_email"
                />
                <span
                  class="md-error"
                  v-if="!$v.form_data.signature_email.required"
                  >The field is required</span
                >
                <span
                  class="md-error"
                  v-if="!$v.form_data.signature_email.email"
                  >Enter a valid email</span
                >
              </md-field>
            </div>
          </div>
        </md-content>

        <md-button class="md-raised md-primary" @click="setDone('seventh')">
          Save</md-button
        >
        <md-button class="md-raised md-primary" @click="getDocusignInfo()"
          ><md-progress-spinner
            class="md-accent"
            :md-diameter="20"
            md-mode="indeterminate"
            v-if="loading"
          ></md-progress-spinner>
          Get document to sign</md-button
        >
      </md-step>
    </md-steppers>
  </div>
</template>

<script>
import axios from "axios";
//import qs from "qs";
import { eventBus } from "../../main";
import states from "./states.js";
import form_data_manager from "./form_data_manager.js";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  numeric,
  maxLength,
} from "vuelidate/lib/validators";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "CustomerFormContent",
  mixins: [validationMixin],
  data: () => ({
    entity: null,
    customer_data: {},
    auth_data: {
      access_token: null,
      account_id: null,
    },
    form_data: {
      // STEP ONE
      supra_point_of_contact: null,
      company_name: null,
      company_phone: null,
      company_fax: null,
      company_address: null,
      selected_state: null,
      company_city: null,
      company_zip: null,
      federal_id_number: null,
      years_in_business: null,
      type_of_business: null,
      how_refered: null,
      credit_requested: null,
      projected_loads: null,
      dyb: null,
      // STEP TWO
      company_name_invoice: null,
      contact_billing: null,
      invoice_mail: null,
      invoice_email: null,
      recipients: null,
      send_per_container: null,
      send_per_booking_or_masterbill: null,
      send_per_po: null,
      bill_of_landing: null,
      load_rate_confirmation: null,
      delivery_order: null,
      exam_fee_receipt: null,
      bridge_toll_gate_fee_receipt: null,
      interchange: null,
      proof_of_delivery: null,
      dry_run_tickets: null,
      per_diem_invoice: null,
      chassis_pool_invoice: null,
      pier_pass_receipt: null,
      booking_confirmation: null,
      scale_ticket: null,
      delay_time_screen_shots: null,
      demurrage_receipt: null,
      flip_charge: null,
      additional_procedure: null,
      chassis_ssl_contracts: null,
      // STEP THREE
      name_of_bank: null,
      bank_address: null,
      account_number: null,
      bank_representative: null,
      bank_representative_phone: null,
      bank_representative_fax: null,
      // STEP FOURTH
      name_of_lender: null,
      lender_account_number: null,
      lender_bank_representative: null,
      lender_bank_representative_phone: null,
      lender_bank_representative_fax: null,
      // STEP FIFTH
      creditors: [],
      creditors_uploaded_files: [],
      // STEP SIXTH
      top_customers: [],
      // STEP SEVENTH
      delay_event_contact_name: null,
      delay_event_contact_title: null,
      delay_event_contact_email: null,
      delay_event_contact_phone: null,
      delay_event_contact_fax: null,
      delay_event_contact_mailing_address: null,
      // STEP EIGHTHT
      delay_event_contact_supervisor_name: null,
      delay_event_contact_supervisor_title: null,
      delay_event_contact_supervisor_email: null,
      delay_event_contact_supervisor_phone: null,
      delay_event_contact_supervisor_fax: null,
      delay_event_contact_supervisor_mailing_address: null,
      // STEP NINTH
      signature_name: null,
      signature_last_name: null,
      signature_title: null,
      signature_email: null,
      signature_object: null,
      signature_date: null,
    },
    tempTopCustomer: null,
    temp_data: null,
    customer_key: null,
    active: "first",
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
    seventh: false,
    firstStepError: null,
    secondStepError: null,
    thirdStepError: null,
    fourthStepError: null,
    fifthStepError: null,
    sixthStepError: null,
    seventhStepError: null,
    states: states,
    loading: false,
  }),
  validations: {
    form_data: {
      // STEP 1
      supra_point_of_contact: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      company_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      company_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      company_fax: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      company_address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      company_city: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      company_zip: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
        numeric,
      },
      federal_id_number: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9),
        numeric,
      },
      years_in_business: {
        required,
        numeric,
      },
      type_of_business: {
        required,
      },
      how_refered: {
        required,
      },
      credit_requested: {
        required,
      },
      projected_loads: {
        required,
        numeric,
      },
      dyb: {
        required,
        numeric,
      },
      // STEP 2
      company_name_invoice: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      contact_billing: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      //
      name_of_bank: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      account_number: {
        required,
        numeric,
        maxLength: maxLength(12),
      },
      bank_address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      bank_representative: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      bank_representative_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      bank_representative_fax: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      name_of_lender: {
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lender_account_number: {
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      lender_bank_representative: {
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      lender_bank_representative_phone: {
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      lender_bank_representative_fax: {
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      delay_event_contact_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      delay_event_contact_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      delay_event_contact_email: {
        required,
        email,
      },
      delay_event_contact_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      delay_event_contact_fax: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      delay_event_contact_mailing_address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      delay_event_contact_supervisor_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      delay_event_contact_supervisor_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      delay_event_contact_supervisor_email: {
        required,
        email,
      },
      delay_event_contact_supervisor_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      delay_event_contact_supervisor_fax: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      delay_event_contact_supervisor_mailing_address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      signature_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      signature_last_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      signature_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      signature_email: {
        required,
        email,
      },
    },
  },
  created() {
    this.getCustomerData();
    this.getCreditorsUploadedFiles();

    eventBus.$on("refreshCreditorsTable", (item, is_inserting, index) => {
      if (
        this.form_data.creditors === undefined ||
        this.form_data.creditors === null
      ) {
        this.form_data.creditors = [];
      }
      if (is_inserting) {
        this.form_data.creditors.push(item);
      } else {
        this.form_data.creditors[index] = item;
      }
    });

    eventBus.$on("refreshRecipientsTable", (item, is_inserting, index) => {
      if (
        this.form_data.recipients === undefined ||
        this.form_data.recipients === null
      ) {
        this.form_data.recipients = [];
      }

      if (is_inserting) {
        this.form_data.recipients.push(item);
      } else {
        this.form_data.recipients[index] = item;
      }
    });

    eventBus.$on("refreshCreditorsFilelist", () => {
      this.getCreditorsUploadedFiles();
    });
  },
  methods: {
    fieldExists(field) {
      if (field === null || field === undefined) {
        return false;
      } else {
        return true;
      }
    },
    getCreditorsUploadedFiles() {
      this.customer_key = this.$route.params.customer;
      axios
        .get(process.env.VUE_APP_GETLIST_FILES, {
          params: {
            partitionKey: this.customer_key,
          },
        })
        .then((response) => {
          this.form_data.creditors_uploaded_files = response.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.form_data.creditors_uploaded_files = error.response.data.data;
          }
        });
    },
    deleteCreditorFile(item) {
      this.customer_key = this.$route.params.customer;
      axios
        .delete(process.env.VUE_APP_DELETE_FILE, {
          data: {
            file_name: item.name,
            partitionKey: this.customer_key,
          },
        })
        .then((response) => {
          this.showMessageService(response.data.message);
          this.getCreditorsUploadedFiles();
        });
    },
    gotoCreditorFile(url) {
      window.open(url, "_blank");
    },
    deleteRecipient(index) {
      this.form_data.recipients.splice(index, 1);
    },
    deleteCreditor(index) {
      this.form_data.creditors.splice(index, 1);
    },
    addTopCustomer() {
      this.form_data.top_customers = this.form_data.top_customers
        ? this.form_data.top_customers
        : [];
      this.form_data.top_customers.push(this.tempTopCustomer);
      console.log(this.form_data.top_customers);
      this.tempTopCustomer = null;
    },
    deleteTopcustomer(index) {
      this.form_data.top_customers.splice(index, 1);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form_data[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    getCustomerData() {
      this.customer_key = this.$route.params.customer;
      axios
        .get(process.env.VUE_APP_GET_CUSTOMER_INFO, {
          params: {
            customer: this.customer_key,
          },
        })
        .then((response) => this.setCustomerData(response.data));
    },
    setCustomerData(data) {
      this.customer_data = data.customer;

      this.form_data = form_data_manager.setFormData(
        this.form_data,
        JSON.parse(data.customer.form_data)
      );

      // saving local copy
      this.temp_data = JSON.parse(JSON.stringify(this.form_data));
    },
    saveUpdateCustomerData: function () {
      // Evaluate if it is necessary to edit
      if (
        form_data_manager.formDataHasChanged(this.temp_data, this.form_data)
      ) {
        this.customer_data.form_data = this.form_data;
        axios
          .post(process.env.VUE_APP_UPDATE_CUSTOMER_INFO, {
            customer: JSON.stringify(this.customer_data),
          })
          .then((result) => {
            // renew local variable
            this.showMessageService(result.data.message);
          })
          .catch((error) => {
            this.showMessageService(error);
          });
      } else {
        // console.log("form has not changed");
      }
    },
    getDocusignInfo() {
      try {
        if (this.validateCustomerForm()) {
          this.loading = true;
          this.getDocuSignToken();
        } else {
          this.loading = false;
          this.showMessageService("Please fill out all fields");
        }
      } catch (error) {
        this.loading = false;
        this.showMessageService(error);
      }
    },
    getDocuSignToken() {
      this.customer_key = this.$route.params.customer;
      axios
        .get(process.env.VUE_APP_GET_DOCUSIGN_TOKEN, {
          params: {
            customer: this.customer_key,
          },
        })
        .then((response) => this.setDocuSignToken(response.data));
    },
    setDocuSignToken(data) {
      this.auth_data.access_token = data.access_token;
      this.auth_data.account_id = data.account_id;

      // save auth_data in local storage
      localStorage.setItem("auth_data", JSON.stringify(this.auth_data));

      this.getDocumentToSign();
    },
    getDocumentToSign() {
      this.customer_key = this.$route.params.customer;

      var customer_info = {
        customer: {
          PartitionKey: this.customer_key,
          RowKey: this.customer_key,
        },
        auth_data: JSON.parse(localStorage.getItem("auth_data")),
      };

      axios
        .get(process.env.VUE_APP_GET_DOCUSIGN_FILE, {
          params: {
            customer_info: JSON.stringify(customer_info),
          },
        })
        .then((response) => this.setDocumentToSign(response.data));
    },
    setDocumentToSign(data) {
      if (data.reason) {
        this.showMessageService(data.message);
      } else {
        localStorage.setItem("envelope_id", JSON.stringify(data.envelope_id));
        window.location.href = data.redirect_url;
      }
    },
    setDone(id, index) {
      this[id] = true;
      this.saveUpdateCustomerData();

      if (index) {
        this.active = index;
      }
    },
    validateCustomerForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        return true;
      }
      return false;
    },
    showUploadDialog() {
      eventBus.$emit("showUploadFileDialog", this.customer_key);
    },
    setError() {
      this.secondStepError = "This is an error!";
    },
    showAddEditCreditors: function (item, index) {
      eventBus.$emit("showAddEditCreditors", item, index);
    },
    showAddEditRecipients: function (item, index) {
      eventBus.$emit("showAddEditRecipients", item, index);
    },
    showMessageService: function (msg) {
      eventBus.$emit("showMessageService", msg);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "disclaimertheme",
  (
    accent: md-get-palette-color(grey, 300),
  )
);

//@import "~vue-material/dist/base/theme";
@import "vue-material/dist/components/MdContent/theme";
.md-checkbox {
  display: flex;
}
.md-steppers {
  height: 100%;
  width: 100%;
}

.customer-form-content {
  padding: 20px;
}

.disclaimer {
  padding: 15px 20px;
}

.disclaimer-content {
  margin: 8px auto;
}
</style>
