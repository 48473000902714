<template>
  <md-dialog :md-active.sync="showUploadFileDialog">
    <md-dialog-title>Select file to upload</md-dialog-title>

    <form novalidate class="md-layout" @submit.prevent="uploadFile">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Select file</label>
                <md-file
                  v-model="single"
                  accept=".pdf,.doc"
                  @md-change="onChange($event)"
                />
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions>
          <md-button class="md-accent" @click="showUploadFileDialog = false"
            >Close</md-button
          >
          <md-button type="submit" class="md-primary" :disabled="sending"
            >Upload file</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </md-dialog>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";

export default {
  name: "CreditorsUploadFileDialog",
  data: () => ({
    customer_key: null,
    showUploadFileDialog: false,
    current_customer: null,
    single: null,
    file: null,
    base64doc: null,
    sending: false,
  }),
  created() {
    eventBus.$on("showUploadFileDialog", (customer_key) => {
      this.customer_key = customer_key;
      this.showUploadFileDialog = true;
    });
  },
  methods: {
    onChange(files) {
      this.file = files[0];

      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.base64doc = reader.result.replace("data:", "").replace(/^.+,/, "");
      };
    },
    uploadFile: function () {
      this.sending = true;

      axios
        .post(process.env.VUE_APP_UPLOAD_FILE, {
          partitionKey: this.customer_key,
          file_name: this.file.name,
          base64_pdf: this.base64doc,
        })
        .then((res) => {
          this.sending = false;
          this.showUploadFileDialog = false;
          this.showMessageService(res.data.message);
          eventBus.$emit("refreshCreditorsFilelist");
        })
        .catch((error) => {
          this.sending = false;
          this.showUploadFileDialog = false;
          this.showMessageService(error);
          eventBus.$emit("refreshCreditorsFilelist");
        });
    },
    showMessageService: function (msg) {
      eventBus.$emit("showMessageService", msg);
    },
  },
};
</script>

<style scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 768px;
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
