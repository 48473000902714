<template>
  <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>
      It seems that you already started a form with your email
    </md-dialog-title>
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100 md-small-size-100">
        <md-card-content class="align-content-center">
          <div class="md-layout md-gutter md-alignment-top-center">
            <div class="md-layout-item text-center">
              Do you want to start with the previous data?
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <md-button class="md-secondary" @click="continueWithForm"
            >Continue editing</md-button
          >
          <md-button class="md-primary" @click="createNewForm"
            >Create new form</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </md-dialog>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";

export default {
  name: "ExistingCustomerDialog",
  data: () => ({
    showDialog: false,
    customer: null,
    entity: null,
  }),
  created() {
    eventBus.$on("showExistingCustomerDialog", (customer, entity) => {
      this.showDialog = true;
      this.customer = customer;
      this.entity = entity;
    });
  },
  methods: {
    clear() {
      this.showDialog = false;
      this.customer = null;
      this.entity = null;
    },
    createNewForm() {
      this.showDialog = false;
      this.customer["disabled"] = true;
      const payload = {
        customer: JSON.stringify(this.customer),
      };
      axios
        .post(process.env.VUE_APP_UPDATE_CUSTOMER_INFO, payload)
        .then((result) => {
          console.log(result);
          this.showMessageService(result.data.message);
          this.customer = null;
        })
        .catch((error) => {
          this.showMessageService(error);
        });
      axios
        .post(process.env.VUE_APP_SAVE_CUSTOMER_INFO, {
          customer: this.entity,
        })
        .then((result) => {
          this.showMessageService(result.data.message);
          this.entity = null;
          this.$router.push({
            name: "CustomerForm",
            params: { customer: result.data.partition_key },
          });
        })
        .catch((error) => {
          this.showMessageService(error);
        });
      this.clear();
    },
    continueWithForm() {
      this.showDialog = false;
      this.customer["disabled"] = false;
      this.saveCustomer(this.customer);
      this.clear();
    },
    saveCustomer(customer) {
      const payload = {
        customer: JSON.stringify(customer),
      };
      axios
        .post(process.env.VUE_APP_UPDATE_CUSTOMER_INFO, payload)
        .then((result) => {
          console.log(result);
          this.showMessageService(result.data.message);
          this.customer = null;
          this.$router.push({
            name: "CustomerForm",
            params: { customer: result.data.partition_key },
          });
        })
        .catch((error) => {
          this.showMessageService(error);
        });
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
    showMessageService: function (msg) {
      eventBus.$emit("showMessageService", msg);
    },
  },
};
</script>

<style scoped>
.md-dialog /deep/.md-dialog-container {
  max-width: 768px;
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.text-center {
  text-align: center;
}
</style>
